import React, {useState, useEffect, useMemo, useCallback, useContext} from 'react'
import lunr from 'lunr'
import _ from 'lodash'
import styled, {css} from 'styled-components/macro'
import Table from '../../components/Table'
import CatalogueIcon from '../../components/CatalogueIcon'
import Layout from '../../components/Layout'
import media from '../../styles/media'
import {MagnifyingGlassIcon, PlusIcon} from '@heroicons/react/24/solid'
import Logo from "../../images/logo-simple.inline.svg"
import {useScrollPosition} from "@n8tb1t/use-scroll-position"
import SearchNote from "../../components/SearchNote"
import {isLoggedIn} from "../../services/auth"
import EditIcon from '@mui/icons-material/Edit'
import {Link} from "gatsby"
import Button from "@mui/material/Button"
import {CatalogueContext} from "../../catalogue/CatalogueContext"
import {SEO} from "../../components/SEO"
import classnames from "classnames"

/*
id
catalogueNo
title
year
source
people
tags
links
notes
category
  name
  code
  icon
subject
format
place
status
images
documents
 */

const searchKeys = [
  // 'AddedBy',
  // 'CatNo',
  // 'Cat',
  // 'Date',
  // 'FormatID',
  // 'HyperLink',
  // 'Notes',
  // 'People',
  // 'Source',
  // 'StatusID',
  // 'SubjectArea',
  // 'Tags',
  // 'Title',
  'title',
  'catalogueNo',
  // 'category.name',
  'categoryName',
  'year',
  'format',
  'notes',
  'people',
  'source',
  'status',
  'subject',
  'tags',
]

const columns = [
  {
    Header: '',
    accessor: 'Icon',
    centre: true,
    link: true,
  },
  {
    Header: 'Title',
    accessor: 'title',
    link: true,
  },
  {
    Header: 'Year',
    accessor: 'year',
    link: true,
  },
  {
    Header: 'Category',
    accessor: 'categoryName',
    link: true,
    collapse: 'mobile',
  },
  {
    Header: 'Source',
    accessor: 'source',
    link: true,
    collapse: 'mobile',
  },
  {
    Header: 'Catalogue No',
    accessor: 'catalogueNo',
    link: true,
    collapse: 'mobile',
  },
]

const adminCols = [
  {
    Header: '',
    accessor: 'admin',
  },
]

const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  width: 69.906px;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  overflow: hidden;
  &.visible {
    opacity: 1; 
  }
  @media ${media.ltTablet} {
    transition: opacity 300ms ease-in-out, width 300ms ease-in-out;
    width: 0;
    &.visible {
      width: 69.906px;
    }
  }
`

const StyledLogo = styled(Logo)`
  margin-right: 12px;
  height: auto;
  width: 100%;
  cursor: pointer;
`

const Search = styled.div`
  display: flex;
  background: #fff;
  box-shadow: 0 3px 7px -7px rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 1;
`

const SearchWrap = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
  padding: 10px 25px;
  padding-top: 8px;
  justify-content: space-between;
  align-items: center;
  @media ${media.ltTablet} {
    padding: 12px;
  }
`

const SearchInputGroup = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
`

const SearchInputIcon = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  svg {
    width: 20px;
    height: 20px;
    color: rgb(156, 163, 175);
  }
`

const SearchInput = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid rgb(209 213 219);
  border-radius: 5px;
  line-height: 20px;
  padding-left: 40px;
  width: 100%;
  &::placeholder {
    color: rgb(156, 163, 175);
    opacity: 1;
  }
`

const TableIcon = styled(CatalogueIcon)`
  display: flex;
  justify-content: center;
`

const AdminButton = styled(Button)`
    border: none!important;
    background: var(--color-icon)!important;
    color: var(--color-textSecondary)!important;
    font-size: 12px!important;
    padding: 1px 9px!important;
    &:hover {
      background-color: #9699a1!important;
      border: none!important;
    }
    @media ${media.ltTablet} {
      padding: 4px 6px!important;
      min-width: auto!important;
      span:first-child {
        margin-right: 0!important;
      }
      .text {
        display: none;
      }
    }
`

const LinkForButton = styled(Link)`
  &:hover{
    text-decoration: none;
  }
`

const AddButton = styled(Link)`
  background: var(--color-editorPrimary);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  min-width: 42px;
  height: 42px;
  border-radius: 100%;
  transition: color 0.15s ease-in-out, background-color 0.15s;
  cursor: pointer;
  margin-left: 12px;
  &:hover {
    text-decoration: none;
  }
  &:hover{
    text-decoration: none;
    background: var(--color-editorPrimaryDark);
  }
  svg {
    height: 25px;
    width: 25px;
    fill: white;
  }
  @media ${media.ltTablet} {
    width: 38px;
    min-width: 38px;
    height: 38px;
  }
`

const AdminComponent = ({ catNo }) => {
  return (
    <div>
      <LinkForButton to={`${catNo}/edit`}>
        <AdminButton variant="outlined" startIcon={<EditIcon fontSize="inherit" />} size="small">
          <span className="text">Edit</span>
        </AdminButton>
      </LinkForButton>
    </div>
  )
}

const PAGE_SIZE = 50

const Index = ({location}) => {
  const [searchTerm, setSearchTerm] = useState((new URLSearchParams(location.search)).get('q') || '')
  const [page, setPage] = useState(parseInt((new URLSearchParams(location.search)).get('p')) || 1)
  const [sticking, setSticking] = useState(false)
  const [debouncing, setDebouncing] = useState(false)

  const { entries } = useContext(CatalogueContext)

  const { rows, index } = React.useMemo(() => {
    if (!entries) return { rows: [] }
    const searchRows = entries.map((row) => ({
      ...row,
      categoryName: row.category.name,
      Icon: <TableIcon type={row.category.icon} colour="var(--color-icon)" />,
      admin: <AdminComponent catNo={row.catalogueNo} />,
      link: `/catalogue/${row.catalogueNo}`,
      year: row.year && row.yearCirca ? `c.${row.year}` : row.year,
    }))
    const rows = _.keyBy(searchRows, 'catalogueNo')
    const index = lunr((ind) => {
      ind.ref('catalogueNo')
      searchKeys.forEach((k) => ind.field(k))
      searchRows.forEach((r) => ind.add(r))
    })
    return { rows, index }
  }, [entries])

  const [results, setResults] = useState(() => {
    if (!searchTerm || !index) return []
    return index.query((q) => {
      q.term(lunr.tokenizer(searchTerm), { boost: 100, usePipeline: true })
      q.term(lunr.tokenizer(searchTerm), { boost: 10, usePipeline: false, wildcard: lunr.Query.wildcard.LEADING | lunr.Query.wildcard.TRAILING })
      q.term(lunr.tokenizer(searchTerm), { boost: 5, editDistance: 1 })
      q.term(lunr.tokenizer(searchTerm), { boost: 1, editDistance: 2 })
    })
  })
  // console.log(results)

  const debouncedSearch = useCallback(_.debounce((term) => {
    // console.log('Search Debounced')
    setPage(1)
    setDebouncing(false)
    if (!index || term === '') {
      window.history.replaceState(null, null, `/catalogue`)
      return setResults([])
    }
    window.history.replaceState(null, null, `?${(new URLSearchParams({q: term})).toString()}`)
    setResults(index.query((q) => {
      q.term(lunr.tokenizer(term), { boost: 100, usePipeline: true })
      q.term(lunr.tokenizer(term), { boost: 10, usePipeline: false, wildcard: lunr.Query.wildcard.LEADING | lunr.Query.wildcard.TRAILING })
      q.term(lunr.tokenizer(term), { boost: 5, editDistance: 1 })
      q.term(lunr.tokenizer(term), { boost: 1, editDistance: 2 })
    }))
  }, 350), [index])

  const onPrevPage = useCallback((e) => {
    e.preventDefault()
    setPage(page - 1)
    window.history.replaceState(null, null, `?${(new URLSearchParams({q: searchTerm, p: page - 1})).toString()}`)
  }, [page, searchTerm])

  const onNextPage = useCallback((e) => {
    e.preventDefault()
    setPage(page + 1)
    window.history.replaceState(null, null, `?${(new URLSearchParams({q: searchTerm, p: page + 1})).toString()}`)
  }, [page, searchTerm])

  const pagination = useMemo(() => ({
    total: results.length,
    pageSize: PAGE_SIZE,
    page: page,
    onPrevPage,
    onNextPage,
    prevPageHref: `?${(new URLSearchParams({q: searchTerm, p: page - 1})).toString()}`,
    nextPageHref: `?${(new URLSearchParams({q: searchTerm, p: page + 1})).toString()}`,
  }), [page, results, onPrevPage, onNextPage, searchTerm])

  const tableRows = useMemo(() => {
    const minIdx = PAGE_SIZE * (page - 1)
    const maxIdx = minIdx + PAGE_SIZE
    return results.filter((result, idx) => idx >= minIdx && idx < maxIdx).map((r) => rows[r.ref])
  }, [results, page])

  useScrollPosition(
    ({prevPos, currPos}) => {
      if (currPos.y < -104 && !sticking) setSticking(true)
      if (currPos.y > -104 && sticking) setSticking(false)
    },
    [sticking],
    null,
    false,
    100,
  )

  // TODO Context?
  const cols = useMemo(() => {
    if (!isLoggedIn()) return columns
    return [...columns, ...adminCols]
  }, [isLoggedIn()])

  // TODO Context?
  const loggedIn = useMemo(() => isLoggedIn(), [isLoggedIn()])

  return (
    <Layout full logoVisible={!sticking}>
      <Search>
        <SearchWrap>
          <LogoWrap className={classnames(sticking && 'visible')}>
            <StyledLogo onClick={() => { window.scroll({ top: 0, left: 0, behavior: 'smooth' })} } />
          </LogoWrap>
          <SearchInputGroup>
            <SearchInputIcon>
              <MagnifyingGlassIcon />
            </SearchInputIcon>
            <SearchInput
              placeholder="Search Catalogue"
              onChange={(e) => {
                setSearchTerm(e.target.value)
                debouncedSearch(e.target.value)
                setDebouncing(true)
              }}
              value={searchTerm}
              // disabled={!index}
            />
          </SearchInputGroup>
          { loggedIn && (
            <AddButton to="/catalogue/add">
              <PlusIcon />
            </AddButton>
          )}
          { !loggedIn && <div/>}
        </SearchWrap>
      </Search>
      {results.length === 0 && !debouncing && (
        <SearchNote type={searchTerm === '' ? 'empty': 'no-results'} />
      )}
      <Table
        columns={cols}
        data={tableRows}
        pagination={pagination}
      />
    </Layout>
  )
}

export default Index

export const Head = ({ location }) => (
  <SEO title="Catalogue" pathname={location.pathname} />
)
