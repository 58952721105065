import React from 'react'
import styled from 'styled-components/macro'
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline"
import SadSearchIcon from "../images/icons/sad-search.inline.svg"
import media from "../styles/media"

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 25px;
  @media ${media.ltTablet} {
    padding: 15px 15px;
  }
`

const ContentWrap = styled.div`
  display: flex;
  background: var(--color-backgroundSecondary);
  max-width: 900px;
  flex: 1;
  padding: 40px 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  @media ${media.ltTablet} {
    padding: 25px 15px;
  }
`

const IconWrap = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${media.ltTablet} {
    flex: 1;
    align-items: flex-start;
    margin-right: 12px;
  }
  svg {
    color: var(--color-icon);
    max-height: 60px;
    width: 100%;
  }
  svg g {
    stroke: var(--color-icon)!important;
  }
`

const InfoWrap = styled.div`
  flex: 8;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  a {
    text-decoration: underline;
    font-weight: 500;
  }
`

const types = {
  empty: {
    header: 'Harbury Heritage Catalogue',
  },
  'no-results': {
    header: 'No results found',
  }
}

const SearchNote = ({type}) => (
    <Wrap>
      <ContentWrap>
        <IconWrap>
          {type === 'empty' && <MagnifyingGlassIcon />}
          {type === 'no-results' && <SadSearchIcon />}
        </IconWrap>
        <InfoWrap>
          <h2>{types[type].header}</h2>
          {type === 'empty' && (
            <ul>
              <li>Use the search box above to enter a word or phrase to search for.</li>
              <li>Once you have found the catalogue entry make a note of the catalogue number (XX-0000).</li>
              <li><a href="/visit">Visit</a> the Harbury Hertigate Centre to obtain the corresponding physical entry.</li>
              <li>If you can&apos;t find what you are looking for please <a href="/contact">contact us</a>.</li>
            </ul>
          )}
          {type === 'no-results' && (
            <ul>
              <li>Sorry we couldn&apos;t find any results matching that search term.</li>
              <li>Try adjusting your search term or filters to be less specific.</li>
              <li>If you still can&apos;t find what you are looking for please <a href="/contact">contact us</a>.</li>
            </ul>
          )}
        </InfoWrap>
      </ContentWrap>
    </Wrap>
)

export default SearchNote
