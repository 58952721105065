import React, {useMemo} from 'react'
import styled, { css } from 'styled-components/macro'
import { useTable } from 'react-table'
import {bodyFontFamily} from "../styles/typography"
import media from "../styles/media"
import { Link } from "gatsby"
import classnames from "classnames"

const TableWrap = styled.div`
  width: 100%;
  padding: 12px 25px;
  @media ${media.ltTablet} {
    padding: 12px 10px;
  }
  
  .show-mobile {
    display: none;
  }
  
  @media ${media.ltTablet} {
    .collapse-mobile {
      display: none;
    }
    .show-mobile {
      display: block;
    }
  }
`

const TableElement = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  background: #fff;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  
  th {
    text-align: left;
    padding: 13px 8px;
    border: none;
  }
  
  tr {
     padding: .35em;
     :nth-child(odd) {
      background: var(--color-tableRow);
    }
  }
  
  td {
    border: none;
    text-align: left;
    padding: 13px 8px;
  }
  
  thead {
    position: sticky;
    inset-block-start: 59.9913px;
    z-index: 1;
    @media ${media.ltTablet} {
      inset-block-start: 65.9913px;
    }
  }
  
  tfoot {
    position: sticky;
    inset-block-end: 0;
    z-index: 1;
  }
 
  
  thead tr,
  tfoot tr {
    :nth-child(odd) {
      background: none;
      background-color: rgba(249, 250, 251, 0.75);
    }
    background: none;
    border: none;
    backdrop-filter: blur(8px);
    background-color: rgba(249, 250, 251, 0.75);
  }
  
  tfoot th {
    padding: 0;
  }
  
  td > a {
    display: block;
    text-decoration: none;
    color: var(--color-text);
    padding: 13px 8px;
  }
  td.has-link {
    padding: 0;
  }
  
  tr:hover  {
    //background: #e9eaee;
    background: #e6e7ee;
  }
`

const Pagination = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-top: 1px solid #ccc;
  p {
    margin-bottom: 0;
    font-weight: normal;
    font-size: 15px;
  }
  @media ${media.ltTablet} {
    padding: 10px 12px;
  }
`


const SimpleButton = styled.a`
  font-family: ${bodyFontFamily.join(', ')};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: 0;
  padding: 5px 16px;
  line-height: 1.5;
  border: 1px solid rgb(209 213 219);
  color: var(--color-text);
  background: var(--color-white);
  transition: color 0.15s ease-in-out, background-color 0.15s;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  font-weight: normal;
  border-radius: 3px;
  margin-left: 10px;
  font-size: 15px;
  &:hover {
    text-decoration: none;
    background: var(--color-whiteHover);
  }
  ${props => props.disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `}
`

const Table = ({ columns, data, pagination }) => {
  const tableInstance = useTable({ columns, data })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  const paginationStr = useMemo(() => {
    const { pageSize, page, total } = pagination
    const first = pageSize * (page - 1) + 1
    const last = pageSize * page < total ? pageSize * page : total
    return (
      <>
        <span className="collapse-mobile">Showing {first.toLocaleString()} to </span>
        <>{last.toLocaleString()} of {total.toString()} results</>
      </>
    )
  }, [pagination])

  if (data.length === 0) return null

  return (
    <TableWrap>
      <TableElement {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className={classnames(!!column.collapse && `collapse-${column.collapse}`)}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
          ))}
        </thead>
        <tfoot>
          <tr>
            <th colSpan={headerGroups[0].headers.length}>
              <Pagination>
                <p>{paginationStr}</p>
                <div>
                  <SimpleButton
                    onClick={pagination.onPrevPage}
                    disabled={pagination.page === 1}
                    href={pagination.prevPageHref}
                  >
                    <>
                      <span className="collapse-mobile">Previous</span>
                      <span className="show-mobile">&lt;</span>
                    </>
                  </SimpleButton>
                  <SimpleButton
                    onClick={pagination.onNextPage}
                    disabled={pagination.pageSize * pagination.page >= pagination.total}
                    href={pagination.nextPageHref}
                  >
                    <span className="collapse-mobile">Next</span>
                    <span className="show-mobile">&gt;</span>
                  </SimpleButton>
                </div>
              </Pagination>
            </th>
          </tr>
        </tfoot>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className={classnames([
                      !!cell.column.link && 'has-link',
                      !!cell.column.collapse && `collapse-${cell.column.collapse}`,
                    ])}
                  >
                    {!cell.column.link && cell.render('Cell')}
                    {cell.column.link && (
                      <Link to={cell.row.original.link} state={{ prevPath: window.location.href }}>
                        {cell.render('Cell')}
                      </Link>
                    )}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </TableElement>
    </TableWrap>
  )
}

export default React.memo(Table)
